<template>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvas">
    <div class="offcanvas-header">
      <input class="offcanvas-button" type="image" src="https://i.imgur.com/3WU93zX.png" data-bs-dismiss="offcanvas" alt="offcanvas" @click="redirectPage('home')">
    </div>
    <div class="offcanvas-body">
      <ul>
        <li data-bs-dismiss="offcanvas" v-if="$parent.isSignIn && $parent.isStudent" @click="redirectPage('profile')"><label>Hi! 小海綿 {{ $parent.user.nickname }}</label></li>
        <li data-bs-dismiss="offcanvas" v-else-if="$parent.isSignIn && !$parent.isStudent" @click="redirectPage('profile')"> <label>Hi! 教練 {{ $parent.user.nickname }} </label></li>
        <li data-bs-dismiss="offcanvas" v-else></li>
        <li data-bs-dismiss="offcanvas" v-if="$parent.isSignIn && $parent.isStudent"><label>等級: {{ level }}</label></li>
        <li data-bs-dismiss="offcanvas" v-if="$parent.isSignIn && $parent.isStudent"><label>積分: {{ $parent.user.point }}</label></li>
        <br>
        <li @click="redirectPage('marquee')" data-bs-dismiss="offcanvas" v-show="!$parent.isStudent && $parent.isSignIn"><label>跑馬燈</label></li>
        <li @click="redirectPage('medalLog')" data-bs-dismiss="offcanvas" v-show="$parent.isSignIn"><label>徽章紀錄</label></li>
        <li @click="redirectPage('medal')" data-bs-dismiss="offcanvas" v-show="$parent.isSignIn"><label>徽章列表</label></li>
        <li @click="redirectPage('training')" data-bs-dismiss="offcanvas"><label>團練表單</label></li>
        <li @click="redirectPage('user')" data-bs-dismiss="offcanvas" v-show="!$parent.isStudent && $parent.isSignIn"><label>小海綿列表</label></li>
        <li @click="redirectPage('service')" data-bs-dismiss="offcanvas"><label>我們提供</label></li>
        <li @click="redirectPage('auth')" data-bs-dismiss="offcanvas"><label>{{ $parent.isSignIn ? '登出': '登入' }}</label></li>
      </ul>
    </div>
  </div>
</template>

<script>
import menuMixins from '@/mixins/menuMixins';
// import Cookies from 'js-cookie';
// import { useRoute } from 'vue-router';

export default {
  mixins: [menuMixins],
  mounted() {
    // // 未登入時自動彈出 offcanvas
    // const offcanvas = document.getElementById('offcanvas');
    // const display = window.getComputedStyle(offcanvas).display;
    // const route = useRoute();
    // if (display !== 'none' && !Cookies.get('hi-manta-sign-in') && route.path === '/') {
    //   offcanvas.classList.add('show');
    // }
  },
};
</script>
