export default {
  data() {
    return {
      tabName: this.$route.name.toLowerCase(),
      pathMap: {
        home: '/',
        user: '/user',
        auth: '/auth',
        profile: '/profile',
        medal: '/medal',
        medalLog: '/medal-log',
        marquee: '/marquee',
        training: '/training',
        service: '/service',
      },
      guestPathMap: {
        home: '/',
        auth: '/auth',
        training: '/training-guest',
        service: '/service',
      },
    };
  },
  computed: {
    level() {
      return `${this.$parent.user.aida_level ?? '-'} / ${this.$parent.user.molchanovs_level ?? '-'} / ${this.$parent.user.mfi_mermaid_level ?? '-'} / ${this.$parent.user.mfi_dance_level ?? '-'}`;
    },
  },
  methods: {
    redirectPage(name) {
      const map = this.$parent.isSignIn ? this.pathMap : this.guestPathMap;
      const path = map[name];
      if (!path) {
        this.$router.push('/');
      } else {
        this.$router.push(path);
      }
    },
  },
};
