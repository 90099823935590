import { initializeApp } from 'firebase/app';
import { toast } from 'vue3-toastify';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Cookies from 'js-cookie';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FCM_API_KEY,
  authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FCM_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FCM_MESSAGIG_SENDER_ID,
  appId: process.env.VUE_APP_FCM_APP_ID,
  measurementId: process.env.VUE_APP_FCM_MEASUREMENT_ID,
};

// 初始化 Firebase
export const setupFirebase = initializeApp(firebaseConfig);

// 取得 getMessaging實例
export const messaging = getMessaging(setupFirebase);

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  toast(payload.notification.body);
});

getToken(messaging, { vapidKey: process.env.VUE_APP_FCM_VAP_ID_KEY })
  .then((currentToken) => {
    if (currentToken) {
      Cookies.set('hi-manta-FCM-token', currentToken);
      console.log('currentToken', currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  })
  .catch((error) => {
    console.log('An error occurred while retrieving token. ', error);
  });
